<template>
  <OrdersTable company></OrdersTable>
</template>

<script>
import OrdersTable from "@/view/components/tables/orders/OrdersTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    OrdersTable,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Manage Orders") },
    ]);
  },
};
</script>
